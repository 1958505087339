body {
  margin: 0;
  font-family: 'kufam ';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


* {
  padding: 0 ;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  font-family: "Kufam";

}

html {
  scroll-behavior: smooth;
}
