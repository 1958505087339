:root {
  --custom-padding: 20px 80px;
}

.header__section {
  background-image: url("https://res.cloudinary.com/dw3vqhvte/image/upload/v1721660331/hero_png_nsnsgd.png");
  background-position: center;
  background-size: cover;
  font-family: "Kufam";
  padding-bottom: 150px;
  margin-top: 0;
}

.header__text {
  color: #fff;
  padding: 20px px;
  margin-top: 100px;
  width: 80%;
  font-family: "Kufam";
  font-size: 65px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -1.44px;
}
.header__text__con {
  height: 40vh;
}
.btn__link {
  display: flex;
  align-items: center;
}

.btn {
  color: var(--Gulf-Blue-950, #001a52);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  border-radius: 80px;
  background: #fcfdff;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  gap: 10px;
  margin-top: 40px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease,
    box-shadow 0.3s ease;
}
.btn:hover {
  transform: scale(1.05); /* Slightly increase size */
}

.btn:hover svg {
  transform: translateX(5px); /* Slightly move the arrow to the right */
}

.header__text span {
  color: #edf2f8;
}

.header__mini__text {
  width: 64%;
  justify-content: space-around;
  transform: translateX(450px);
  font-size: 19px;
  line-height: 30px;
  display: flex;
}

.consultation__con {
  padding: 10px 10px;

}

.consultation__text {
  color: #fff;
}

.socials__icons {
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease, transform 0.3s ease,
    box-shadow 0.3s ease;
}

.socials__icons a :hover {
  border-radius: 100px;
  transition: 0.3s ease;
  transform: scale(1.1);
}

.video__con {
  width: 100%;
  margin: 0 auto;
  /* transform: translateY(-100px); */
  /* margin-bottom: 100px; */
}

.inner__video__con {
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  height: 50vh;
}

video {
  width: 100%;
  /* border-radius: 15px 15px; */
  background-color: #080808;
  object-fit: contain;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.who__head__text {
  color: var(--pic-bg, #2e4a77);
  /* Display lg Bold */
  font-family: Kufam;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -0.96px;
}

.who__more__info {
  display: flex;
  justify-content: center;
  gap: 40px;
}

.img__con {
  width: 100%;
}

.img__con img {
  width: 100%;
}

.who__section {
  margin-top: 100px;
}

.who_left_con {
  display: flex;
  width: 85%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 48px;
}

.who__text__con {
  padding: 80px 5px;
  padding-top: 170px;
  width: 100%;
  color: var(--Cod-Gray-950, #080808);
  font-family: Kufam;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
}

.who__inner__con {
  width: 100%;
  margin: 0 auto;
}

.learn {
  margin-top: 40px;
}

.who_link {
  color: var(--Gulf-Blue-950, #001a52);
  font-family: Kufam;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  padding: 0;
  margin-top: 0;
}

.projects__section {
  padding: 20px 0;
}
.projects__con {
  display: flex;
  background: url("https://res.cloudinary.com/dw3vqhvte/image/upload/v1720306426/image_sjarma.png");
  text-align: center;
  align-items: center;
  justify-content: space-around;
  align-self: stretch;
}

.project__info {
  border-right: 1px solid var(--pic-bg, #2e4a77);
  display: flex;
  align-items: center;
  flex-direction: column;
  color: var(--Cod-Gray-50, #f6f6f6);
  text-align: center;
  padding: 0 55px;
  justify-content: center;
  font-family: Kufam;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.project__big_t {
  margin-bottom: 30px;
  margin-top: 100px;
}

.project__info p {
  margin-bottom: 100px;
  text-transform: uppercase;
}

.final {
  border-right: none;
}

.service__inner__con {
  display: flex;
  justify-content: center;
  gap: 50px;
  align-items: center;
}

.service__image {
  margin-bottom: 50px;
}

.service__inner__con img {
  width: 100%;
}

.service__left__con {
  width: 50%;
}

.our__service__heading {
  color: rgba(8, 28, 70, 0.44);
  text-align: center;
  font-family: Kufam;
  font-size: 72px;
  font-style: normal;
  font-weight: 700;
  line-height: 90px;
  letter-spacing: -1.44px;
  margin-bottom: 90px;
}

.service__left__con h1 {
  color: var(--pic-bg, #2e4a77);
  font-family: Kufam;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -0.96px;
  margin-bottom: 20px;
  padding-top: 130px;
}

.service__left__con p {
  color: var(--Cod-Gray-950, #080808);
  font-family: Kufam;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 40px;
}

.service__right__con p {
  color: var(--Cod-Gray-950, #080808);
  font-family: Kufam;
  /* font-size: 18px; */
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
  margin-bottom: 20px;
}

.service__right__con {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.list__item {
  display: flex;
  /* margin-bottom: 10px;  */
  gap: 14px;
  text-transform: capitalize;
  align-items: center;
}

.engineering__con {
  display: flex;
  gap: 50px;
  margin-top: 200px;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.engineering__img {
  width: 50%;
}

.engineering__img img {
  width: 100%;
}

.engineering__text__con {
  width: 50%;
}

.engineering__text__con h1 {
  color: var(--pic-bg, #2e4a77);
  font-family: Kufam;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -0.96px;
  margin-top: 20px;
}

.engineering__text__con p {
  color: var(--Cod-Gray-950, #080808);
  font-family: Kufam;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-top: 23px;
}
.landscaping {
  flex-direction: row-reverse;
}
.build__section {
  margin-top: 200px;
}
.build__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}

.build__header h1 {
  color: var(--pic-bg, #2e4a77);
  text-align: center;
  font-family: Kufam;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -0.96px;
}

.build__header a .btn {
  border-radius: 80px;
  background: #081c46;
  color: #fff;
  backdrop-filter: blur(1.5px);
  margin-top: 0;
}

.build__image__con {
  display: flex;
  gap: 10px;
}

.big__image {
  width: 50%;
}

.big__image img {
  width: 100%;
}

.double__Image {
  display: flex;
  flex-direction: column;
  width: 52%;
  gap: 10px;
  margin-bottom: 50px;
}

.double__Image img {
  width: 100%;
}

.who_link p {
  color: var(--Gulf-Blue-950, #001a52);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  padding: 0;
  margin-top: 10px;
}

.header__mini__text button {
  border: none;
  background-color: inherit;
}

.popup__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.494);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
}

.pop__input__con {
  display: flex;
  gap: 10px;
}

.pop__input__con input {
  background-color: #d2d2d2;
  border: none;
  outline: none;
  height: 50px;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 30px;
}
.name__form select {
  background-color: #d2d2d2;
  border: none;
  outline: none;
  height: 50px;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 30px;
}

textarea {
  padding: 10px;
}
.pop__message__con {
  width: 80%;
}

.Popup__main {
  width: 100%;
}

.pop__message__con textarea {
  width: 100%;
  background-color: #d2d2d2;
  height: 34vh;
  border: none;
  outline: none;
  border-radius: 10px;
}

.consultation__form {
  display: flex;
  justify-content: space-between;
  gap: 70px;
  padding: 20px;
  border-radius: 10px;
}

.pop__input__con__single input {
  background-color: #d2d2d2;
  border: none;
  outline: none;
  height: 50px;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
}

.pop_btn {
  width: 100%;
  border: none;
}

.popup__form__con {
  background-color: rgb(232, 232, 232);
  width: 80vw;
  padding: 20px;
  margin: 0 auto;
  margin-top: 10px;
  border-radius: 10px;
  overflow-x: hidden; /* Hide any potential overflow */
}

.popup__header {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  align-items: center;
  font-size: 20px;
  color: #2e4a77;
}

.close__btn {
  border: none;
  outline: none;
  border-radius: 100%;
  background-color: #d2d2d2;
  width: 50px;
  height: 50px;
  font-size: 25px;
  cursor: pointer;
}
.close__btn:hover {
  background-color: inherit;
}

@media (max-width: 1184px){
  .header__mini__text {
    transform: translateX(300px);
    width: 70%;
  }

}
  

@media (max-width: 984px) {
  .header__mini__text {
    line-height: 26px;
    transform: translateX(300px);
    gap: 20px;
    width: 60%;
    padding-top: 130px;
  }
  .projects__con {
    flex-direction: column;
  }
  .project__info {
    border-right: 0;
    border-bottom: 1px solid var(--pic-bg, #2e4a77);
    width: 100%;
  }
  .consultation__form {
    flex-direction: column;
  }

  .popup__form__con {
    padding: 20px;
    margin-top: 200px;
    padding-bottom: 100px; /* Ensures space for the keyboard */
  }

  .Popup__main {
    width: 100%;
  }
  .pop__message__con {
    width: 100%;
  }
  .popup__header {
    font-size: 16px;
    padding: 0;
  }
  .who__more__info{
    flex-direction: column;
  }
  .header__text__con{
    height: 30vh;
  }

  .popup__header {
    padding: 30px 0;
  }

  .who__text__con{
    padding-top: 0;
    padding-right: 120px;
  }
  
  .engineering__con h1{
    font-size: 35px;
  }
}

@media (max-width: 768px) {
  .header__text {
    font-size: 40px;
    width: 100%;
  }
  /* video {
    height: 35vh;
  } */

  .video__con {
    margin-bottom: 200px;
  }

  .header__mini__text {
    font-size: 30px;
    line-height: 26px;
    transform: translateX(0);
    gap: 20px;
    width: 50%;
  }

  .popup__form__con {
    padding: 20px;
    margin-top: 900px;
    width: 90%;
    overflow-y: auto;
    height: 25%; /* or any other appropriate height */
    padding-bottom: 100px; /* Ensures space for the keyboard */
  }

  .popup__header {
    font-size: 16px;
    padding: 0;
  }

  .pop__input__con {
    display: flex;
    flex-wrap: wrap;
  }

  .consultation__form {
    display: flex;
    padding: 0;
    gap: 0;
  }

  .popup__header {
    padding: 30px 0;
  }

  .header__text {
    font-size: 40px;
    letter-spacing: 0.64px;
  }
  .btn {
    font-size: 15px;
    /* padding: 10px 9px;
    gap: 7px; */
  }

  .header__mini__text {
    font-size: 20px;
    line-height: 28px;
    width: 100%;
  }

  .who__head__text {
    font-size: 30px;
    line-height: 40px;
  }

  .who__text__con {
    padding: 40px 5px;
    line-height: 28px;
  }

  .our__service__heading {
    font-size: 48px;
    line-height: 60px;
    margin-top: 60px;
    margin-bottom: 20px;
  }

  .service__left__con h1 {
    font-size: 32px;
    line-height: 40px;
    padding-top: 80px;
  }

  .service__left__con p {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 20px;
  }

  .engineering__con {
    flex-direction: column;
    gap: 20px;
    margin-top: 40px;
  }

  .engineering__img {
    width: 100%;
  }

  .engineering__text__con {
    width: 100%;
  }

  .engineering__text__con h1 {
    font-size: 35px;
    line-height: 40px;
    margin-top: 10px;
  }

  .engineering__text__con p {
    font-size: 18px;
    margin-top: 15px;
  }
  .build__section {
    margin-top: 70px;
  }
  .engineering__con {
    margin-top: 100px;
  }
  .list__con {
    margin-top: 30px;
  }
  .project__big_t h1 {
    font-size: 48px;
  }
  .project__big_t p {
    font-size: 20px;
  }
  .project__info {
    padding: 0 0;
  }
  .who__more__info {
    display: flex;
    justify-content: center;
    gap: 40px;
    flex-direction: column;
  }

  .who_left_con {
    width: 100%;
    gap: 20px;
  }
  .projects__con {
    flex-direction: column;
  }
  .project__info {
    border-right: 0;
    border-bottom: 1px solid var(--pic-bg, #2e4a77);
    width: 100%;
  }
  .service__inner__con {
    flex-direction: column;
    gap: 0;
  }

  .service__left__con {
    width: 100%;
  }
  .service__right__con {
    width: 100%;
    flex-direction: column;
  }
  .engineering__con {
    flex-direction: column;
  }
  .engineering__img {
    width: 100%;
  }
  .engineering__img img {
    width: 100%;
  }
  .engineering__text__con {
    width: 100%;
  }
  .build__header {
    flex-direction: column;
    gap: 20px;
  }
  .double__Image {
    width: 100%;
  }
  .build__image__con {
    flex-direction: column;
  }
  .big__image {
    width: 100%;
  }

  .learn {
    margin-top: 35px;
    color: var(--Gulf-Blue-950, #001a52);
    font-size: 15px;
  }
  .list__con {
    margin-top: 30px;
  }
  .project__big_t h1 {
    font-size: 48px;
  }
  .project__big_t p {
    font-size: 20px;
  }
  /* .video__con {
    width: 100%;
    height: 30vh;
    border-radius: 15px;
    margin: 0 auto;
    transform: translateY(-100px);
  } */

  .header__text{
    padding-right: 60px ;
  }

  .header__text__con{
    height: 20vh;
  }
  .service__image {
    margin-bottom: 20px;
  }
}

@media (max-width: 580px) {
  .header__text {
    font-size: 38px;
    line-height: 53px; /* 165.625% */
    margin-top: 50px;
  }

  .header__mini__text{
    padding-top: 150px;
  }
  .build__header h1 {
    color: var(--pic-bg, #2e4a77);
    text-align: center;
    font-family: Kufam;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -0.96px;
  }
  .consultation__con {
    padding: 0;
  }

  .btn {
    margin-top: 50px;
    /* padding: 15px 20px; */
  }

  .btn p{
    font-size: 13px;
  }

  .who_link {
    margin-top: 0;
  }

  .header__mini__text {
    color: var(--Cod-Gray-50, #f6f6f6);
    font-size: 16px;
    line-height: 28px;
    width: 100%;
    margin-bottom: 20px;
  }

  .who__head__text {
    font-size: 30px;
    line-height: 30px;
  }

  .who__text__con {
    padding-top: 10px;
    font-size: 16px;
    line-height: 26px;
  }

  .our__service__heading {
    font-size: 36px;
    line-height: 45px;
    margin-top: 20px;
  }

  .service__left__con h1 {
    font-size: 28px;
    line-height: 24px;
    padding-top: 60px;
  }

  .service__left__con p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 15px;
  }

  .engineering__con {
    gap: 15px;
    margin-top: 100px;
  }

  .engineering__text__con h1 {
    font-size: 28px;
    line-height: 30px;
    margin-top: 5px;
  }

  .engineering__text__con p {
    margin-top: 20px;
    font-size: 16px;
    line-height: 24px;
  }

  .list__con {
    margin-top: 30px;
  }
  .project__big_t h1 {
    font-size: 48px;
  }
  .project__big_t p {
    font-size: 20px;
  }
}

@media (max-width: 380px) {
  .header__mini__text{
    padding-top: 200px;
  }

}

