.heading__btn {
  font-size: 18px;
  font-weight: 600;
  color: #5d5c5c;
  cursor: pointer;
  display: flex;
  padding: 8px 25px;
  border-radius: 50px;
  border: 2px solid;
  background: inherit;
  transition: transform 0.3s ease, color 0.3s ease;
  outline: none;

}

.heading__con {
  margin-bottom: 40px;
  padding: 15px;
  overflow-x: auto;
  display: flex;
  justify-content: flex-start;


}
.image__con {
  filter: saturate(0.1);
}
.image__con:hover {
  filter: saturate(1);
}
.image__con:hover {
  background-color: var(--Picture--bg, #081c464d);
  cursor: pointer;
}


.heading__btn.active {
  background-color: var(--Gulf-Blue-950, #001a52);
  color: white; /* Active text color */
}

.heading__btn:hover {
  background-color: var(--Gulf-Blue-950, #001a52dd);
  color: white; /* Active text color */
}





