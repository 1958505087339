.home__nav img {
  width: 60px;
}
.logo__con img {
  width: 100%;
}

.home__nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  padding: 20px 0;
  cursor: pointer;
  z-index: 2000;
  top: 0;
  border-bottom: 2px solid rgba(255, 255, 255, 0.119);
}

.home__nav ul {
  display: flex;
  justify-content: space-between;
  width: 50%;
  font-size: 16px;
}

.nav_link {
  color: #fff;
  transition: transform 0.3s ease, color 0.3s ease;
}

.nav_link:hover {
  transform: scale(1.1);
  color: var(--Gulf-Blue-950, #ffffff31);
}
.home__nav button{
  display:none;
  background-color: red;
}



/* .home__nav li {
  color: #fff;
  z-index: 2000;
  padding-right: 40px;
}

.home__nav li:nth-child(5) {
  padding-right: 0px !important;
} */

@media (max-width: 1024px) {
  .home__nav ul li a:not(.active) {
    opacity: 0.5;
  }
  .home__nav ul li {
    position: relative;
    z-index: 1000;
  }

 

  .home__nav ul {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    font-size: 36px;
    flex-direction: column;
    overflow: hidden;
  }

  .home__nav button span {
    height: 2px;
    width: 30px;
    display: block;
    background-color: #fff;
    z-index: 1000;
    transform-origin: center;
    transition: 0.5s;
    cursor: pointer;
  }

  .home__nav button {
    border: none;
    z-index: 2000;
    position: relative;
    transition: 0.3s;
    display: block;
    background-color: transparent;
    border: none;
    z-index: 2000;
    position: relative;
    transition: 0.3s;
  }

  .open {
    display: flex !important;
    pointer-events: unset !important;
    opacity: 1 !important;
    font-size: 10px;
  }
  .home__nav button span:nth-child(1) {
    margin-bottom: 5px;
  }

  .home__nav button span:nth-child(2) {
    width: 30px;
  }

  .menu span:last-child {
    transform: translateY(1px) rotate(-45deg);

    width: 30px;
  }
  .menu span:first-child {
    transform: translateY(8px) rotate(45deg);
    width: 30px;
  }
  .dropdown {
    display: flex;
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  .colors {
    display: inline-block;
    background-color: #001a52;
    flex: 1;
    transition: 0.25s;
    transform: translateY(-100%);
  }
  .open .colors {
    transform: translateY(0%) !important;
  }
  .home__nav li {
    opacity: 0;
    transition: 0.15s;
    padding: 0;
  }
  .open li {
    opacity: 1;
    transition-delay: 0.1s;
  }
}
