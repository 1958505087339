.contact__header {
  padding: 0 0;
}
.contact__header__text__con {
  width: 60%;
}
.contact__header__text__con p {
  color: var(--Cod-Gray-50, #f6f6f6);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
  margin-top: 20px;
  padding-bottom: 120px;
}
.contact__header {
  margin-top: 100px;
}
.input__con {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
}
.name__form {
  width: 100%;
}
.form-section {
  margin-bottom: 200px;
}

.form__container {
  display: flex;
  justify-content: space-between;
  gap: 100px;
}
.form__container form {
 width: 40vw; 
  
}

.form__container form input {
  background-color: #e7e7e7;
  border: none;
  outline: none;
  height: 50px;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
}

.social__info {
  margin: 20px 0;
}
.Social__title h4 {
  color: #081c46;
}
.Social__title p {
  color: #404041;
}
.text__area__con {
  width: 100%;
}
.text__area__con textarea {
  width: 100%;
  background-color: #e7e7e7;
  border: none;
  outline: none;
  border-radius: 10px;
  padding: 10px;
}

.form__btn {
  display: flex;
  background: #001a52;
  padding: 20px;
  color: white;
  border-radius: 30px;
  gap: 20px;
  text-transform: capitalize;
  justify-content: center;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease,
    box-shadow 0.3s ease;
}

.form__btn:hover {
  transform: scale(1.05); /* Slightly increase size */
}

.Form__btn:hover svg {
  transform: translateX(5px); /* Slightly move the arrow to the right */
}

.social__info__con {
  max-width: 800px;
  width: 100%;
}

.social__info__con img {
  width: 100%;
  height: auto;
}
.social__info {
  display: flex;
  gap: 5px;
  align-items: center;
}

@media (max-width: 900px) {
  .form__container {
    flex-direction: column;
  }
  /* .form__main {
    width: 100%;
  } */

  .social__info {
    margin-bottom: 20px;
    width: 100%;
  }
  .contact__header__text__con {
    width: 70%;
  }

  .form__container {
    display: flex;
    justify-content: space-between;
    gap: 100px;
  }
  .social__info__con {
    width: 100%;
    max-width: 600px;
  }
  .form__container form {
    width: 100%; 
     
  }
}

@media (max-width: 480px) {
  .contact__header__text__con {
    width: 100%;
  }

  .form__container form {
    width: 100%; 
     
  }
}
