/* Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(26, 27, 32, 0.973);
  display: flex; 
  align-items: center;
  justify-content: center;
  
}
.no-scroll {
  overflow: hidden;
} 

.modal-content {
  padding: 10px;
  position: relative;
  /* max-width: 60%;
  max-height: 80%; */

  
}

.close-button {
  position: absolute;
  top: 10px;
  right: 30PX;
  background: rgba(0, 0, 0, 0.556);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  font-size: 30px;
  cursor: pointer;
  color: white;
}

