/* Carousel.css */
.carousel {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.carousel img {
  width: 100%;
  max-width: 700px;
  height: 650px;
  object-fit: cover;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 24px;
  z-index: 1;
  border-radius: 60%;
  width: 50px;
  height: 50px;
}

.carousel-button.left {
  left: 10px;
}

.carousel-button.right {
  right: 10px;
}
