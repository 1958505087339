

.consult__section {
  background-image: url("https://res.cloudinary.com/dw3vqhvte/image/upload/v1720842289/Frame_ancssh.png");
  background-position: top center;
  background-size: cover;
  padding: 140px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.consult {
  max-width: 600px; /* Adjust the width as needed */
  text-align: center;
}
.consult h1 {
  font-size: 50px;
  font-weight: 700;
  line-height: 72px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #2e4a77;
  margin-bottom: 30px;
}

.consult h3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  color: #4f4f4f;
  padding: 0 60px;
}
.consult__btn{
  background-color:#001a52;
  color: white;
}
.consult{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


 
@media (max-width: 768px) {


  .consult h1 {
    font-size: 38px;
    line-height: 60px;
    width:100%;
  }

  .consult h3 {
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    color: #4f4f4f;
  }



  
} 
